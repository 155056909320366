<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.saccadic_scanning") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-layout align-center justify-space-around row>
        <v-flex xs11>
          <v-select v-model="chars" :items="chars_options" :label="$t('tool_track_letters.characters')" />
        </v-flex>
      </v-layout>
      <v-layout align-center justify-space-around row>
        <v-flex xs3>
          <v-text-field
            v-model="num_exercises"
            :label="$t('tool_track_letters.number_exercises')"
            type="number"
            min="1"
            max="100"
            :suffix="$t('tool_track_letters.exercises')"
          />
        </v-flex>

        <v-flex xs3>
          <v-text-field v-model="font_size" :label="$t('tool_track_letters.font_size')" type="number" min="6" max="48" suffix="pt" />
        </v-flex>

        <v-flex xs3>
          <v-text-field v-model="line_height" :label="$t('tool_track_letters.line_spacing')" type="number" min="50" max="300" suffix="%" />
        </v-flex>

        <v-flex xs11>
          <v-select v-model="font" :items="fonts_options" :label="$t('common.font')" />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      num_exercises: 5,
      font_size: 11,
      line_height: 100,
      chars: "pseudowords",
      font: "Arial",
      chars_options: [
        {
          text: this.$t("tool_track_letters.pretend_words"),
          value: "pseudowords",
        },
        {
          text: this.$t("tool_track_letters.random_numbers_and_letters"),
          value: "both",
        },
        {
          text: this.$t("tool_track_letters.random_numbers_only"),
          value: "numbers",
        },
        {
          text: this.$t("tool_track_letters.random_letters_only"),
          value: "letters",
        },
      ],
      fonts_options: [
        { text: "Arial", value: "Arial" },
        { text: "Courier", value: "Courier" },
        { text: "Times", value: "Times New Roman" },
        { text: "Georgia", value: "Georgia" },
        { text: "Verdana", value: "Verdana" },
        { text: "Comic Sans", value: "Comic Sans MS" },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let uri = "/api/tools/pdf/track_letters.pdf";
      uri += "?num_exercises=" + this.num_exercises;
      uri += "&font_size=" + this.font_size;
      uri += "&font=" + encodeURIComponent(this.font);
      uri += "&chars=" + this.chars;
      uri += "&line_height=" + this.line_height / 100;
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: this.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
