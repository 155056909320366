<template>
  <v-app>
    <UserSignUp :user_signup_token="user_signup_token" :require_tos_scroll="true" />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import UserSignUp from "../../shared/components/UserSignUp.vue";

export default {
  components: { UserSignUp },
  data() {
    return {
      user_signup_token: this.$route.params.token ? this.$route.params.token : "",
    };
  },
  computed: {
    ...mapState["current_user"],
  },
  mounted() {
    // If we have a current user, redirect to main app page
    if (this.current_user) {
      this.$router.push("/");
    }

    // TODO: If we don't have a token, redirect to default signup token
  },
};
</script>

<style></style>
